import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

function ComoComprarPage() {
  return (
    <section className="px-2 py-6 md:px-16">

      <SEO
        keywords={[`about`, `sobre`, `trocas`, `dovolucoes`, `devoluções`]}
        title="Trocas e Devoluções"
      />

    <div className="text-base text-gray-700">
      <div className="px-6 md:px-20 md:mx-20">
        <h4 className="pt-8 text-xl uppercase font-bold">Como Comprar</h4>
        <h4 className="pt-8 uppercase font-bold">Estrutura de medidas das peças</h4>
        <div className="pb-4">
          Em breve...
        </div>

      </div>
    </div>

    </section>
  );
}

export default ComoComprarPage;
